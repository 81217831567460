import React,{useState, useEffect, useContext} from 'react'
import {Link,Redirect,useHistory} from 'react-router-dom'

import Header from './Header'
import Sidebar from './Sidebar'
import AuthContext from '../../context/AuthContext'
import BarChart from '../../utils/BarChart'

import './Dashboard.css'

let urls = '/api/v1/'

function Dashboard() {
  let {user, authTokens, logOut} = useContext(AuthContext);
  const [hotels, setHotels] = useState(null)
  const [rooms, setRooms] = useState(null)
  const [places, setPlaces] = useState(null)
  let fetchList =async(item)=>{
    console.log("fetching...");
    let response = await fetch(urls+item,{
      method: "GET",
      headers:{
        "Content-Type": "application/json",
        "Authorization": "Bearer " + String(authTokens),
      }
    } 
    );
    let data = await response.json();
    if(response.status === 200){
      if (item==='hotels'){
        var temp = data.data
        setHotels({
          labels:temp.map((tem)=>tem.name),
          datasets:[
            {
              label:'Rooms',
              data:temp.map((tem)=>tem.rooms.length),
              backgroundColor:['red','blue','green','orange','grey','black']
            },
          ],
        })
      }
      if (item==='rooms'){
        var temp = data.data
        setRooms({
          labels:temp.map((tem)=>(tem.title+' ~ '+tem.hotelName)),
          datasets:[
            {
              label:'Requests',
              data:temp.map((tem)=>tem.requests),
              backgroundColor:['red','blue','green','orange','grey','black']
            },
          ],
        })
      }
      if (item==='places'){
        var temp = data.data
        setPlaces({
          labels:temp.map((tem)=>tem.name),
          datasets:[
            {
              label:'Requests',
              data:temp.map((tem)=>tem.requests),
              backgroundColor:['red','blue','green','orange','grey','black']
            },
          ],
        })
      }
      console.log(data.data)
    }else if(response.status===401){
      logOut();
    }
  };
  useEffect(()=>{
    fetchList('hotels');
    fetchList('rooms');
    fetchList('places');
  },[])
  return (
    <body>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <main role="main" className="col-md-9 col-lg-10 ml-sm-auto pt-3 px-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                      <h1 className="h2">Dashboard</h1>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-6 p-5">
                      <h2>Hotels</h2>
                      <div className="mb-3">
                        {hotels!==null?<BarChart chartData={hotels}/>:''}
                      </div>
                      <Link to="/dashboard/hotels"><small>Move To Hotels</small></Link>
                    </div>
                    <div className="col-md-12 col-lg-6 p-5">
                      <h2>Rooms</h2>
                      <div className="mb-3">
                        {rooms!==null?<BarChart chartData={rooms}/>:''}
                      </div>
                      <Link to="/dashboard/rooms"><small>Move To Rooms</small></Link>
                    </div>
                    <div className="col-md-12 col-lg-6 p-5">
                      <h2>Places</h2>
                      <div className="mb-3">
                        {places!==null?<BarChart chartData={places}/>:''}
                      </div>
                      <Link to="/dashboard/places"><small>Move To Places</small></Link>
                    </div>
                  </div>
                </main>
            </div>
        </div>
    </body>
  )
}

export default Dashboard