import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom';

import Restaurant from '../../static/restaurant.jpg'
import {addRoom, removeRoom} from '../../utils/CartFunctionality'

function Room() {
    const {id} = useParams()
    const [room, setRoom] = useState({});
    const [hotel, setHotel] = useState({});
    const [location, setLocation] = useState({});
    const [imageLink, setImageLink] = useState();
    const history = useHistory()

    let fetchRoom = async() =>{
        const response = await fetch(`/api/v1/rooms/${id}`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json'
            }
        });
        let data = await response.json();
        if(response.status===200){
            setRoom(data.data);
            setHotel(data.data.hotel);
            setLocation(data.data.hotel.location);
            setImageLink(`/uploads/${data.data.photo}`);
        }else{
            console.log('error')
        }
    }
    let addToCart = async(e)=>{
        e.preventDefault();
        addRoom(room, e.target.start.value, e.target.end.value);
        document.querySelector('#form').reset();
        toast.success('Remember to Checkout.')
    }
    let addToCartAndRedirect = async()=>{
        addRoom(room, document.querySelector('#start').value, document.querySelector('#end').value);
        document.querySelector('#form').reset();
        history.push('/checkout')
    }
    useEffect(()=>{
        fetchRoom();
    },[])
    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-md-12 col-lg-6 d-flex align-items-center">
                    <img className="d-block w-100 border-top border-bottom" src={imageLink} alt={room.title}/>
                </div>
                <div className="col-md-12 col-lg-6 py-3 d-flex align-items-center">
                <div>
                    <h2>{room.title} ~ <span className="text-muted">{hotel.name}</span></h2>
                    <h3>{location.city}</h3>
                    <p><span className="bold money">{room.price}</span><span className="aftermoney text-muted">{room.days}</span></p>
                    <div className="py-2 h6 general-description">
                        <span className="">rooms: {room.rooms}</span>
                        <span className="mx-5"> bath: {room.days}</span>
                    </div>
                    <p className="detail-description">{room.description}</p>
                    <hr/>
                    <form onSubmit={addToCart} id="form">
                        <h1>Book This room</h1>
                        <div className="form-group">
                            <label>Start Date</label>
                            <input type="datetime-local" name="start" id="start" className="form-control" required/>
                        </div>
                        <div className="form-group">
                            <label>End Date</label>
                            <input type="datetime-local" name="end" id="end" className="form-control" required/>
                        </div>
                        <div className="form-group">
                            <input type="submit" name="submit" className="btn btn-success my-2"/>
                            <button type="button" name="submitandredirect" className="btn btn-success my-2 mx-3" onClick={addToCartAndRedirect}>Submit and Checkout</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Room