import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import Menu from './Menu';
import Social from './Social'

import './Footer.css';
import './NavBar.css'

let urls = '/api/v1/'
let start_slice = 0
let end_slice = 6

function Footer() {
    const [hotels, setHotels] = useState(null)
    const [places, setPlaces] = useState(null)
    let fetchList =async(item)=>{
        console.log("fetching...");
        let response = await fetch(urls+item,{
        method: "GET",
        headers:{
            "Content-Type": "application/json"
        }
        } 
        );
        let data = await response.json();
        if(response.status === 200){
            if (item==='hotels'){
                setHotels(data.data.slice(start_slice,end_slice))
            }
            if (item==='places'){
                setPlaces(data.data.slice(start_slice,end_slice))
            }
            console.log(data.data)
            console.log(data.data)
        }else if(response.status===401){
            console.log('data not found')
        }
    };
    useEffect(()=>{
        fetchList('hotels');
        fetchList('places');
    },[])
    return (
        <footer className="blog-footer bg-dark p-5 d-flex justify-content-center">
            <div className="">    
                <Link to="/" className='text-light h1 text-uppercase text-decoration-none bold'>Ari Global Tours</Link>
                
                <div className="row pt-4">
                    <div className="col-sm-12 col-md-6 col-lg-3 pb-5">
                        <h2 className="text-light">About Us</h2>
                        <p className="text-light lead">
                            Visit a country worth a thousand experiences. <br/>From Mountain Gorillas, Parks, Lakes and a people with a unified cultural history.
                        </p>
                        <h4 className="text-light mt-4">Contact</h4>
                        <ul className="list-unstyled">
                            {Social.map((item, index) => {
                                return (
                                    <div className='' key={index}>
                                        {item}
                                    </div>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 pb-5">
                        <h4 className="text-light">Menu</h4>
                        {Menu.map((item, index) => {
                            return (
                                <div className='lead text-light text-decoration-none' key={index}>
                                    {item}
                                </div>)
                        })}
                        <Link to="/login" className='lead text-light text-decoration-none'>
                            Admin
                        </Link>
                        
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 pb-5">
                        <h4 className="text-light">Where to stay</h4>
                        <ul className="list-unstyled">
                            {(hotels!==null)?hotels.map((item, index) => {
                                return (
                                    <div>
                                        <Link to={'/hotels/'+item.id} className='text-light lead text-decoration-none' key={index}>
                                            {item.name}
                                        </Link>
                                    </div>
                                );
                            }):""}
                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 pb-5">
                        <h4 className="text-light">Where To Visit</h4>
                        <ul className="list-unstyled">
                            {(places!==null)?places.map((item, index) => {
                                return (
                                    <div>
                                        <Link to={'/places/'+item._id} className='text-light lead text-decoration-none' key={index}>
                                            {item.name}
                                        </Link>
                                    </div>
                                );
                            }):""}
                        </ul>
                    </div>

                </div>
            </div>
        </footer>
    );
}

export default Footer;