import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'

import '../homepage/HomePage.css'

import {removePlace, removeRoom} from '../../utils/CartFunctionality'
import MiniNav from '../globals/MiniNav'


function Checkout() {
    let local = localStorage;
    const [items, setItems] = useState({})
    const [places, setPlaces] = useState([])
    const [rooms, setRooms] = useState([])
    let getItems = () =>{
        let cart = JSON.parse(local.getItem('cart'))
        if (local.getItem('cart')===null){
            setItems({});
        }else{
            setItems(cart)
            setPlaces(cart['places'])
            setRooms(cart['rooms'])
        }
    }
    let removeItemPlace=(index)=>{
        removePlace(index)
        getItems();
    }
    let removeItemRoom=(index)=>{
        removeRoom(index)
        getItems();
    }
    let sendInquiryEmail=async(e)=>{
        e.preventDefault();
        if(((e.target.email.value!=='' && e.target.email.value!==null) ||(e.target.phone.value!=='' && e.target.phone.value!==null)) && (places.length!==0 || rooms.length!==0)){
            var subject = "Requesting for a Sum Price for the following items by"+e.target.email.value.split('@')[0]
            var message = `
            Dear Ari Global Tours,

            I would like to place an inquiry on the following items:\n
            Places:\n
            \t${places.map(function(place, index){
                return (
                    `${index!==0?'\r\t\t':''}Place: ${place.place.name}
                    Located: ${place.place.location.city}
                    Price Posted: ${place.place.price}
                    On This Date: ${place.start}\n
                    `
                )
            })}
            Rooms:\n
            \t${rooms.map(function(room, index){
                return (
                    `${index!==0?'\r\t\t':''}Room: ${room.room.title}
                    Hotel: ${room.room.hotelName}
                    Located: ${room.room.city}
                    Price Posted: ${room.room.price}
                    Starting from: ${room.start.split('T')[0]} at: ${room.start.split('T')[0]}
                    Until: ${room.end.split('T')[0]} at: ${room.end.split('T')[0]}\n
                    `
                )
            })}

            Here are some additional requests:\n
            \t${e.target.additional.value}\n

            You can contact me on the following address:\n
            \tEmail: ${e.target.email.value}
            \tPhone: ${e.target.phone.value}
            `
            const response = await fetch('/api/v1/utils/contact',{
                method:'POST',
                headers:{
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({"email":e.target.email.value,"subject":subject,"message":message})
              })
              let data = await response.json();
              if(response.status===200){
                console.log('sent')
                toast.success('We have received your email, we will get in touch.')
                document.querySelector('#form').reset()
                for(var i=0; i<places.length; i++){
                    await fetch('/api/v1/places/'+places[i].place._id+'/requests',{
                        method:'PUT',
                        headers:{
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({})
                      })
                    removeItemPlace(i)
                }
                for(var i=0; i<rooms.length; i++){
                    await fetch('/api/v1/rooms/'+rooms[i].room._id+'/requests',{
                        method:'PUT',
                        headers:{
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({})
                      })
                    removeItemRoom(i)
                }
              }else{
                toast.error('Sorry, something went wrong. Please try again later!')
              }
        }else{
            toast.error('You will need to add some objects to the enquiry or \nAdd an email or phone.')
        }
    }
    useEffect(()=>{
        getItems();
    },[])
    return (
        <div className="container py-3">
            <h1>Enquiry Form</h1>
            <MiniNav/>
            <hr/>
            <div className="row container">
                <div className="col-lg-8 col-md-12">
                    <div className="my-4">
                        <h2 className="text-dark bold">Places:</h2>
                        {places.length!==0?(places.map(function(place, index){
                            var start = place.start
                            var place = place.place
                            return (
                                <div key={index} className="row border mb-2 p-2">
                                    <div className="col-sm-12 col-md-10">
                                        <h5 className="text-secondary bold">{place.name} ~ {place.location.city}</h5>
                                        <p className="money">{place.price}</p>
                                        <p className="text-truncate">{place.description}</p>
                                        <p className="detail-description">Date: <span className="bold">{start.split('T')[0]}</span></p>
                                    </div>
                                    <div className="col-sm-12 col-md-2 d-flex align-items-center">
                                        <button className="btn btn-secondary bold p-3" onClick={()=>removeItemPlace(index)}>Remove</button>
                                    </div>
                                </div>
                            )
                        })):
                        <div className="px-3 py-2">
                            <h4 className=" text-secondary lead">No Checkout for Places to Visit</h4>
                        </div>
                        }
                        <Link to="/places">Add a place to visit</Link>
                    </div>
                    <hr/>
                    <div className="my-4">
                        <h2 className="text-dark bold">Rooms:</h2>
                        {rooms.length!==0?(rooms.map(function(room, index){
                            var start = room.start
                            var end = room.end
                            var room = room.room
                            return (
                                <div key={index} className="row border mb-2 p-2">
                                    <div className="col-sm-12 col-md-10">
                                        <h5 className="text-secondary bold">{room.title} ~ {room.hotelName}</h5>
                                        <p className="money">{room.price}</p>
                                        <p className="text-truncate">{room.description}</p>
                                        <p className="detail-description">Starting Date: <span className="bold">{start.split('T')[0]}</span></p>
                                        <p className="detail-description">Ending Date: <span className="bold">{end.split('T')[0]}</span></p>
                                    </div>
                                    <div className="col-sm-12 col-md-2 d-flex align-items-center">
                                        <button className="btn btn-secondary bold p-3" onClick={()=>removeItemRoom(index)}>Remove</button>
                                    </div>
                                </div>
                            )
                        })):
                        <div className="px-3 py-2">
                            <h4 className=" text-secondary lead">No Checkout for Rooms</h4>
                        </div>
                        }
                        <Link to="/rooms">Add a room</Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 my-4">
                    <h2 className="text-dark bold">Personal Information</h2>
                    <form className="py-4" onSubmit={sendInquiryEmail} id='form'>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1" className="bold">Email</label>
                            <input type="email" className="form-control" id="exampleFormControlInput1" name="email" placeholder="name@example.com"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1" className="bold">Phone</label>
                            <input type="tel" className="form-control" id="exampleFormControlInput1" name="phone" placeholder="+250781234567"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1">Additional Information</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" name="additional" rows="3"></textarea>
                        </div>
                        <div className="form-group py-3">
                            <button className="btn btn-primary btn-md" type="submit">Email an Inquiry</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Checkout