import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import NotFound from '../globals/NotFound'
import MiniNav from '../globals/MiniNav'

import '../homepage/HomePage.css'

function Hotels() {
    const [hotels, setHotels] = useState([]);

    // fetching rooms
    let fetchHotels = async () =>{
        const response = await fetch('/api/v1/hotels',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        let data = await response.json();
        if(response.status===200){
            setHotels(data.data);
        }else{
            console.log('error');
        }
    }

    useEffect(()=>{
        fetchHotels();
    },[]);

    return (
        <div>
            <section className="jumbotron py-3">
                <div className="container">
                <h1 className="jumbotron-heading">places to stay at</h1>
                <MiniNav/>
                <hr/>
                </div>
            </section>

            <div className="album">
                <div className="container-fluid">
                    <div className="row px-md-3 px-lg-5">
                        {(hotels.length!==0)?hotels.map(function(hotel,index){
                            return(
                                <Hotel hotel={hotel} key={index}/>
                            )
                        }):<NotFound/>} 
                    </div>
                </div>
            </div>
        </div>
    )
}

function Hotel(props){
    let hotel = props.hotel;
    const imageLink = '/uploads/'+hotel.photo;
    const hotelLink = '/hotels/'+hotel.id;
    
    return (
        <div className="col-md-4 col-lg-3 col-xl-3">
            <div className="card mb-4 box-shadow">
                <img className="card-img-top" src={imageLink} alt={hotel.name}/>
                <div className="card-body">
                    <h6 className="card-title bold">{hotel.name}, <span className="text-dark">{hotel.city}</span></h6>
                    <p className='card-text bold'>Average Cost: <span className="money">{hotel.averageCost?hotel.averageCost:"___"}</span> , <span>Average Rating: {hotel.averageRating?hotel.averageRating:"___"}</span></p>
                    <hr/>
                    <p className="bold text-muted text-truncate">{hotel.description}</p>
                    <br/>
                    <Link to={hotelLink} className="card-text">More Info</Link><br/>
                </div>
            </div>
        </div>
    )
}

export default Hotels