import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';

import Contact from '../contact/Contact';
import Regions from '../globals/Regions'
import SomeHotels from './SomeHotels'
import Places from './Places'

import CountrySide from '../../static/countryside.jpg';
import Travel from '../../static/nightFall.PNG';
import Kigali from '../../static/Kigali.jpg';
import Gisenyi from '../../static/Gisenyi.jpg';

import './HomePage.css';
import './cover.css';

function HomePage() {
  return (
    <div className=''>
      <div className="homepage text-center" id="home">
          <div 
            className="masthead pt-5"
            style={{background:`url(${Travel})`}}>
            <div className='color-overlay d-flex justify-content-center align-items-center'>
              <div className="">
                <div>Travel the country of a thousand hills with</div>
                <div>Ari Global Tours</div>
                <div className="mx-5 mt-4 links row d-flex justify-content-center">
                  
                  <HashLink to="#contact" className="btn btn-light btn-lg my-1 rounded-50 button col-sm-12 col-md-5">Drop a Message</HashLink>  
                </div>          
              </div>  
            </div>
          </div>
      </div>
      <Link to="/#about" id={`about`} className="text-decoration-none"></Link>
      <div class="row featurette container py-5 mx-auto">
        <div class="col-md-7 d-flex align-items-center">
          <span>
            <Link to="/#about" id={`about`} className="text-decoration-none"></Link>
            <h2 class="featurette-heading"><span className='bold'>Rwanda!</span></h2>
            <p class="lead featurette-description">Visit a country worth a thousand experiences. <br/>From Mountain Gorillas, Parks, Lakes and a people with a unified cultural history.</p>
          </span>
        </div>
        <div class="col-md-5 d-flex justify-content-center align-items-center">
          <img class="featurette-image img-fluid mx-auto" src={Travel} alt="Traveling Company picture"/>
        </div>
      </div>
      <hr className="container"/>
      <div className="popular container">
        <h1 className="py-4 bold">What To Do?</h1>
        <Places/>
        <Link to="/places" className="bold nav-link">Click for More About Places To Visit</Link>
      </div>

      <hr className="container"/>
      <div className='popular container'>
        <h1 className="py-4 bold">Where To Stay?</h1>
        <SomeHotels/>
        <Link to="/hotels" className="bold nav-link">Click for More About Places To Stay</Link>
      </div>
      
      <hr className='container'/>
      <Contact/>
    </div>
  );
}



export default HomePage;