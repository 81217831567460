import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom';

import {addPlace} from '../../utils/CartFunctionality'

import Restaurant from '../../static/restaurant.jpg'

function Place() {
    const {id} = useParams()
    const [place, setPlace] = useState({});
    const history = useHistory()
    
    let fetchPlace = async() =>{
        const response = await fetch(`/api/v1/places/${id}`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json'
            }
        });
        let data = await response.json();
        if(response.status===200){
            setPlace(data.data);
        }else{
            console.log('error')
        }
    }
    let addToCart = async(e)=>{
        e.preventDefault();
        addPlace(place, e.target.start.value);
        document.querySelector('#form').reset();
        toast.success('Remember to Checkout!')
    }
    let addToCartAndRedirect = async()=>{
        addPlace(place, document.querySelector('#start').value);
        document.querySelector('#form').reset();
        history.push('/checkout')
    }
    useEffect(()=>{
        fetchPlace();
    },[])
    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-md-12 col-lg-6">
                    <img className="d-block w-100 border-top border-bottom" src={'/uploads/'+place.photo} alt={place.name}/>
                </div>
                <div className="col-md-12 col-lg-6 py-3">
                    <div>
                        <h2>{place.name} ~ <span className="text-muted">{place.address}</span></h2>
                        <p><span className="general-description bold">Price: </span><span className="bold money">{place.price}</span></p>
                        <p className="general-description">{place.description}</p>
                    </div>
                    <form onSubmit={addToCart} id="form">
                        <h1>Book This Place</h1>
                        <div className="form-group">
                            <label>Date</label>
                            <input type="datetime-local" name="start" id="start" className="form-control" required/>
                        </div>
                        <div className="form-group">
                            <input type="submit" name="submit" className="btn btn-success my-2"/>
                            <button type="button" name="submitandredirect" className="btn btn-success my-2 mx-3" onClick={addToCartAndRedirect}>Submit and Checkout</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Place