import React from 'react';
import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';

let cart = JSON.parse(localStorage.getItem('cart'));

const menu = [
    <HashLink className='text-decoration-none text-light' to="/">Who we are</HashLink>,
    <Link className='text-decoration-none text-light' to="/places">Things To Do</Link>,
    <Link className='text-decoration-none text-light' to="/hotels">Where To Stay</Link>,
    <Link className="text-decoration-none text-light" to='/rooms'>Check out suites</Link>,
    <Link className="text-decoration-none text-light pb-2" to='/checkout'>Checkout<br/>
        {cart?<small>
            <small className="px-3">{cart!==null?(cart['rooms']?cart['rooms'].length+" rooms":""):""}</small><br/>
            <small className="px-3">{cart!==null?(cart['places']?cart['places'].length+" places":""):""}</small>
        </small>:""}
    </Link>,
    <HashLink className='text-decoration-none text-light' to="/#contact">Talk To Us</HashLink>,
    // <Link className="text-decoration-none" to="/login">Login/Register</Link>,
];

export const menuDark = [
    <HashLink className='text-decoration-none text-dark mini-small' to="/">Who we are</HashLink>,
    <Link className='text-decoration-none text-dark mini-small' to="/places">Things To Do</Link>,
    <Link className='text-decoration-none text-dark mini-small' to="/hotels">Where To Stay</Link>,
    <Link className="text-decoration-none text-dark mini-small" to='/rooms'>Check out suites</Link>,
    <Link className="text-decoration-none text-dark mini-small" to='/checkout'>Checkout</Link>,
    <HashLink className='text-decoration-none text-dark mini-small' to="/#contact">Talk To Us</HashLink>,
    // <Link className="text-decoration-none" to="/login">Login/Register</Link>,
];

export default menu;