/*
cart = {
    places:[
        {
            data:{},
            date:yyyy-mm-dd
        },
        {
            data:{},
            date:yyyy-mm-dd
        }
    ],
    rooms:[
        {
            data:{},
            start:yyyy-mm-dd,
            end:yyyy-mm-dd
        },
        {
            data:{},
            start:yyyy-mm-dd,
            end:yyyy-mm-dd
        }
    ]
}
*/

let local = localStorage;
let temp = {
    places:[],
    rooms:[]
}

function checkLocal(){
    if(local.getItem('cart')===null){
        local.setItem('cart', JSON.stringify(temp));
    }
    // console.log(JSON.parse(local.getItem('cart')))
    return local.getItem('cart')
}

export function addPlace (place, start){
    let cart = JSON.parse(checkLocal());
    let places = cart['places'];
    for (var i=0; i<places.length; i++){
        if(places[i]['place']===place && start===start) {
            return
        }
        else if(places[i]['place']===place && start!==start){
            var tempPlace = {
                place:place,
                start: start
            }
            places[i] = tempPlace;
            cart['places'] = places;
            local.setItem('cart', JSON.stringify(cart));
            return
        }
        else{
            var tempPlace = {
                place:place,
                start: start
            }
            places.push(tempPlace);
            cart['places'] = places;
            local.setItem('cart', JSON.stringify(cart));
            return
        }
    }
    var tempPlace = {
        place:place,
        start: start,
    }
    places.push(tempPlace);
    cart['places'] = places;
    local.setItem('cart', JSON.stringify(cart));
    return
}
export function removePlace (id){
    let cart = JSON.parse(checkLocal());
    let places = cart['places'];
    let tempPlaces = [];
    for(var i=0;i<places.length;i++){
        if(i!==id){
            console.log(id)
            tempPlaces.push(places[i])
        }
    }
    cart['places'] = tempPlaces;
    local.setItem('cart',JSON.stringify(cart));
}

export function addRoom (room, start, end) {
    let cart = JSON.parse(checkLocal());
    let rooms = cart['rooms'];
    for (var i=0; i<rooms.length; i++){
        if(rooms[i]['room']['title']===room['title'] && rooms[i]['start']===start && rooms[i]['end']===end) {
            return
        }
        else if(rooms[i]['room']['title']===room['title'] && (rooms[i]['start']!==start || rooms[i]['end']!==end)){
            var tempRoom = {
                room:room,
                start: start,
                end:end
            }
            rooms[i] = tempRoom;
            cart['rooms'] = rooms;
            local.setItem('cart', JSON.stringify(cart));
            return
        }
        else{
            var tempRoom = {
                room:room,
                start: start,
                end:end
            }
            rooms.push(tempRoom);
            cart['rooms'] = rooms;
            local.setItem('cart', JSON.stringify(cart));
            return
        }
    }
    var tempRoom = {
        room:room,
        start: start,
        end:end
    }
    rooms.push(tempRoom);
    cart['rooms'] = rooms;
    local.setItem('cart', JSON.stringify(cart));
    return
}
export function removeRoom (id){
    let cart = JSON.parse(checkLocal());
    console.log(cart);
    let rooms = cart['rooms'];
    let tempRooms = [];
    for(var i=0;i<rooms.length;i++){
        if(i!==id){
            tempRooms.push(rooms[i])
        }
    }
    cart['rooms'] = tempRooms;
    local.setItem('cart',JSON.stringify(cart));
}
