import React,{useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import NotFound from '../globals/NotFound'

let urls = '/api/v1/hotels?sort=-averageCost&limit=4'

function SomeHotels() {
    let [hotels, setHotels] = useState([]);
    let fetchHotels = async()=>{
        let response = await fetch(urls,{
            method: 'GET',
            headers:{
                'Content-Type': 'application/json'
            }
        })
        const data = await response.json()
        if(response.status === 200){
            setHotels(data.data)
        }
    }

    useEffect(()=>{
        fetchHotels()
    },[]);
    return (
        <div>
            <div className="album">
                <div className="">
                    <div className="row">
                        {(hotels.length!==0)?hotels.map(function(hotel,index){
                            return(
                                <Hotel hotel={hotel} key={index}/>
                            )
                        }):<NotFound/>} 
                    </div>
                </div>
            </div>
        </div>
    )
}

function Hotel(props){
    let hotel = props.hotel;
    const imageLink = '/uploads/'+hotel.photo;
    
    return (
        <div className="col-md-4 col-lg-3 col-xl-3">
            <div className="card mb-4 box-shadow">
                <img className="card-img-top" src={imageLink} alt={hotel.name}/>
                <div className="card-body">
                    <h6 className="card-title bold">{hotel.name} <span className="text-muted">{hotel.city}</span></h6>
                    <p className="text-muted text-truncate">{hotel.description}</p>
                </div>
            </div>
        </div>
    )
}

export default SomeHotels