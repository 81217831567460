import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import NotFound from '../globals/NotFound'
import MiniNav from '../globals/MiniNav';

function Rooms(props) {
    const [rooms, setRooms] = useState([]);

    // fetching rooms
    let fetchRooms = async () =>{
        const response = await fetch('/api/v1/rooms',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        let data = await response.json();
        if(response.status===200){
            setRooms(data.data);
        }else{
            console.log('error');
        }
    }

    useEffect(()=>{
        fetchRooms();
    },[]);
    return (
        <div>
            <section className="jumbotron py-3">
                <div className="container">
                    <h1 className="jumbotron-heading">Check All suites</h1>
                    <MiniNav/>
                    <hr/>
                </div>
            </section>

            <div className="album">
                <div className="container-fluid">
                    <div className="row px-md-3 px-lg-5">
                        {(rooms.length!==0)?rooms.map(function(room,index){
                            return(
                                <Room room={room} key={index}/>
                            )
                        }):<NotFound/>} 
                    </div>
                </div>
            </div>
        </div>
  )
}

const Room = (props) =>{
    let room = props.room;
    const roomLink = "/rooms/"+room._id;
    const imageLink = "/uploads/"+room.photo;
    return (
        <div className="col-md-4 col-lg-3 col-xl-3">
            <div className="card mb-4 box-shadow">
                <img className="card-img-top" src={imageLink} alt={room.name}/>
                <div className="card-body">
                    <h6 className="card-title">{room.title} ~ {room.hotel.name}</h6>
                    <p className="bold text-muted text-uppercase">{room.hotel.location.city}</p>
                    <p className='card-text'><span className="money">{room.price}</span><span className="aftermoney">{room.days}</span></p>
                    <small>Baths: {room.bath}, Rooms: {room.rooms}</small>
                    <br/>
                    <Link to={roomLink}>Read More and Book</Link>
                </div>
            </div>
        </div>
    )
}

export default Rooms