import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import NotFound from '../globals/NotFound'

function Places() {
    const [places, setPlaces] = useState([]);

    // fetching rooms
    let fetchPlaces = async () => {
        const response = await fetch('/api/v1/places?limit=4', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        let data = await response.json();
        if (response.status === 200) {
            setPlaces(data.data);
        } else {
            console.log('error');
        }
    }

    useEffect(() => {
        fetchPlaces();
    }, []);

    return (
        <div>
            <div className="album">
                <div className="">
                    <div className="row d-flex">
                        {(places.length !== 0) ? places.map(function (place, index) {
                            return (
                                <Place place={place} key={index} />
                            )
                        }) : <NotFound />}
                    </div>
                </div>
            </div>
        </div>
    )
}

function Place(props) {
    let place = props.place;
    const imageLink = '/uploads/' + place.photo;
    const emailLink = 'mailto:' + place.email;
    const telLink = 'tel:' + place.phone;
    return (
        <div className="col-md-4 col-lg-3 col-xl-3">
            <div className="card mb-4 box-shadow">
                <img className="card-img-top" src={imageLink} alt={place.name} />
                <div className="card-body">
                    <h6 className="card-title bold">{place.name}</h6>
                    <p className="card-description text-truncate">{place.description}</p>
                </div>
            </div>
        </div>
    )
}

export default Places