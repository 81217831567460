import React, {useContext} from 'react'
import {Link} from 'react-router-dom'

import AuthContext from '../../context/AuthContext'
import './Dashboard.css'

function Sidebar() {
    const {logOut} = useContext(AuthContext);
  return (
    <div className="col-md-3 col-lg-2 pt-4 border text-center bg-light sidebar"> 
        <div className="p-2 mb-4 btn btn-secondary">
            <Link className="text-dark bold text-decoration-none text-uppercase logo" to="/">Ari Global Tours</Link>
        </div>   
        <div className="p-3">
            <Link className="text-decoration-none" to="/dashboard">
                Dashboard
            </Link>
        </div>            
        <div className="p-3">
            <Link className="text-decoration-none" to="/dashboard/places">
                Places
            </Link>
        </div>
        <div className="p-3">
            <Link className="text-decoration-none" to="/dashboard/hotels">
                Hotels
            </Link>
        </div>
        <div className="p-3">
            <Link className="text-decoration-none" to="/dashboard/rooms">
                Rooms
            </Link>
        </div>
        <hr/>
        <div className="p-3">
            <Link className="text-decoration-none" to="/dashboard/users">
                People
            </Link>
        </div>
        <div className="p-3">
            <Link className="text-decoration-none" to="/dashboard/profile">
                Profile
            </Link>
        </div>
        <hr/>
        <div className="p-3">
            <Link className="text-decoration-none" to="/login" onClick={()=>logOut()}>
                LogOut
            </Link>
        </div>            
    </div>
  )
}

export default Sidebar