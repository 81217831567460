import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'

import NotFound from '../globals/NotFound'

function Hotel() {
    const {id} = useParams();
    const [hotel, setHotel] = useState({});
    const [rooms, setRooms] = useState([]);

    let fetchHotel = async()=>{
        const response = await fetch(`/api/v1/hotels/${id}`,{
            method:'GET',
            headers:{
                'Content-Type': 'application/json'
            }
        });
        let data = await response.json();
        if(response.status===200){
            setHotel(data.data);
            const roomsResponse = await fetch(`/api/v1/hotels/${id}/rooms`,{
                method:'GET',
                headers:{'Content-Type': 'application/json'}
            });
            let roomsData = await roomsResponse.json();
            console.log(roomsData.data)
            if(roomsResponse.status===200){
                setRooms(roomsData.data);
            }else{
                console.log('error @ rooms fetching')
            }
        }else{
            console.log('error @ hotel fetching')
        }
    }
    useEffect(()=>{
        fetchHotel();
    },[]);
    return (
        <div className="container py-5 detail-page">
            <h2>Hotel: <span className="text-dark">{hotel.name}</span></h2>
            <h5 className="text-dark">located: {hotel.city}</h5>
            <hr/>
            <div className="d-flex justify-content-center mx-auto">
                <img src={'/uploads/'+hotel.photo} alt={'image for hotel '+hotel.name}/>
            </div>
            <p className="general-description">{hotel.description}</p>
            <h3 className="text-dark">Further Details</h3>
            <p className="general-description">Average Rating: {hotel.averageRating?hotel.averageRating:'___ '}, Average Cost: <span className="money">{hotel.averageCost}</span></p>
            <hr/>
            <h3 className="text-dark">Rooms</h3>
            <hr/>
            {rooms.length===0?<NotFound/>:rooms.map(function(room, index){
                return <Room room={room} index={index}/>
            })}
        </div>
    )
}

function Room(props){
    let room = props.room;
    return (
        <div className="row container-fluid hotel-room">
            <div className="col-md-4 d-flex justify-content-center align-items-center">
                <img src={'/uploads/'+room.photo}/>
            </div>
            <div className="col-md-8">
                <h5>{room.title} ~ <span className="text-dark">{room.hotelName}</span></h5>
                <p className="detail-description">{room.description}</p>
                <div className="row">
                    <div className="col lead">
                        <h6 className="text-dark bold">Room <br/>Descriptions</h6>
                        <span>Rooms: {room.rooms}</span>
                        <br/>
                        <span>Baths: {room.bath}</span>
                    </div>
                    <div className="col lead">
                        <h6 className="text-dark bold">Room <br/>Pricing</h6>
                        <span>Price: <span className="money">{room.price}</span></span>
                        <br/>
                        <span>Days:  {room.days}</span>
                    </div>
                    <Link to={`/rooms/${room._id}`}>Book This Room</Link>
                </div>
            </div>
            <hr/>
        </div>
    )
}

export default Hotel