import React from 'react'

import {menuDark} from './Menu';

import './NavBar.css'

export default function MiniNav() {
  return (
    <div className="pt-2">
        {menuDark.map((item, index) => {
            return (
                <small className='small text-mute' key={index}>
                    {item}{'-'}
                </small>
            );
        })}
    </div>
  )
}
